import BlogDashboard from "../components/page-specific/Blog/BlogDashboard"
import BlogSidebar from "../components/page-specific/Blog/BlogSidebar"
import CtaSection from "../components/sections/CtaSection"
import Layout from "../components/global/Layout"
import React from "react"
import { graphql } from "gatsby"
import { queries } from "../breakpoints"
import styled from "styled-components";
import BlogHeader from "../components/page-specific/Blog/BlogHeader";
import { getFeaturedBlogPosts } from "./blogHelperFunctions"

const BlogIndex = ({
  data,
}) => {
  const [posts, setPosts] = React.useState(data.allWpPost.nodes);
  const [visiblePosts, setVisiblePosts] = React.useState(posts);
  const [activeCategory, setActiveCategory] = React.useState();
  const [searchActive, setSearchActive] = React.useState(false);

  React.useEffect(() => {
    let tagName;
    let replaceSpace;
    if (typeof window !== "undefined") {
      tagName = new URLSearchParams(window.location.search).get("tagName");
      const searchParam = new URLSearchParams(window.location.search).get("s");
      if (searchParam) {
        window.scrollTo(0, 0);
        setSearchActive(true);
        replaceSpace = searchParam.replace("+"," ");
      }
    }
    if (tagName) {
    const postsWithThisTag = posts.filter((post) => {
      const entriesFound = post.tags.nodes.filter((element) => element.name === tagName);
      return entriesFound.length > 0;
    });
      setPosts(postsWithThisTag);
    }
    if (replaceSpace) {
      let searchResults = [];
      posts.forEach((post) => {
        // const stringifyPost = JSON.stringify(post);
        const searchOnly = {
          title: post.title,
          excerpt: post.excerpt,
          tags: post.tags,
          categories: post.categories,
          uri: post.uri,
        }
        const stringifyPostFiltered = JSON.stringify(searchOnly).toLowerCase();
        const entryFound = stringifyPostFiltered.indexOf(replaceSpace.toLowerCase());
        if (entryFound !== -1) {
          searchResults.push(post);
        }
      });
      setVisiblePosts(searchResults);
    }
  }, []);

  let allCategories = [];
  posts.forEach((post) => {
    post.categories.nodes.forEach((categoryNode) => {
      if (categoryNode.name !== 'Uncategorized') allCategories.push(categoryNode.name);
    });
  });
  allCategories = allCategories.filter((value, index, self) => self.indexOf(value) === index);

  const filterByCategory = (categoryName) => {
    if (categoryName) {
      const postsToShow = [];
      posts.forEach((post) => {
        post.categories.nodes.forEach((postCategory) => {
          if (postCategory.name === categoryName) postsToShow.push(post);
        });
      });
      setActiveCategory(categoryName);
      setVisiblePosts(postsToShow);
    } else {
      setActiveCategory();
      setVisiblePosts(posts);
    }
  }

  return(
    <Layout 
        title={"Grubhub Corporate Blog | Corporate Ordering | Virtual Events"}
        description={"Your go-to source for helpful insights on corporate ordering and events from Grubhub Corporate Accounts. Check out our content for corporate ideas and tips."}
        utag_data={{
          pageGroup: "grubhub - lead generation",
          subGroup: "learn more",
          pageName: "blog",
          brand: "grubhub"
        }}
      >
        <BlogContainer>
          <BlogPreheader>Corporate Blog</BlogPreheader>
          <BlogHeader
            posts={getFeaturedBlogPosts(posts)}
            categories={allCategories}
            filterByCategory={filterByCategory}
            activeCategory={activeCategory}
          />
          {searchActive && <BlogSubPreheader>Search results:</BlogSubPreheader>}
          {(() => { 
            if (!posts || posts.length === 0 || !visiblePosts || visiblePosts.length === 0) {
              return (
                <BlogNoPostsFound>
                  No blog posts found.
                </BlogNoPostsFound>
              )
            } else {
              return (
                // <AreDiv className={(searchActive ? 'areSearchActive' : 'nuAreSearchActive')}>
                <BlogDashboard posts={visiblePosts}/>
                // </AreDiv>
              )
            }
          })()}
          <BlogSidebar />
        </BlogContainer>
        <CtaSection headline="See what we can do for you" backgroundColor="#F9F9F9"/>
      </Layout>
  )
}

export default BlogIndex;

export const pageQuery = graphql`
  query WordPressPostArchive {
    allWpPost(
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        content
        categories {
          nodes {
            id
            name
          }
        }
        tags {
          nodes {
            id
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fixed {
                  src
                }
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

const BlogContainer = styled.div`
  padding:50px;
  display:grid;
  grid-template-columns:6fr 2fr;
  grid-template-areas:
    "preheader ."
    "header ."
    "predashboard ."
    "dashboard sidebar";
    @media(${queries.max_break_lg}){
        grid-template-areas:
          "preheader preheader"
          "header header"
          "predashboard predashboard"
          "dashboard dashboard"
          "sidebar sidebar"
    }
    @media(${queries.max_break_sm}){
          padding:30px;

    }
`
const BlogPreheader = styled.h3`
  text-transform: uppercase;
  font-size: var(--fontSize-1);
  letter-spacing:3.67px;
  font-weight: 300;
  color:var(--color-text-light);
  grid-area: preheader;
`
const BlogSubPreheader = styled.h4`
  text-transform: uppercase;
  font-size: var(--fontSize-1);
  letter-spacing:3.67px;
  font-weight: 300;
  margin-top: 25px;
  margin-bottom: 0;
  color:var(--color-text-light);
  grid-area: predashboard;
`
const BlogNoPostsFound = styled.p`
  grid-area: dashboard;
  margin-top: 10px;
`

const AreDiv = styled.div`
  grid-area: dashboard;
`