import { FormContainerSearch, FormContainer, FormStyles, StyledForm } from './BlogSidebar.styles';
import { Sticky, StickyContainer } from 'react-sticky';

import MarketoForm from '../../../global/MarketoForm';
import React from 'react';

import { SearchBar } from "./search";

const BlogSidebar = (props) => {
  
  let query;
  if (typeof window !== "undefined") {
    const { search } = window.location;
    query = new URLSearchParams(search).get('s')
  }
  const [searchQuery, setSearchQuery] = React.useState(query || '');

  return (
    <StickyContainer style={{gridArea: 'sidebar'}}>
      <Sticky>{({ style }) => 
        <FormContainer style={style}>
          <FormContainerSearch>
            <h5>Search</h5>
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </FormContainerSearch>
          <StyledForm> 
            <h5>Stay up to date</h5>
            <p>Sign up to receive email updates and to hear what's going on with our company!</p>
            <FormStyles>
              <MarketoForm 
                id={1688} 
                labelBellow={false}
                eventCategory="blogs page interaction"
                eventAction="subscribe_cta"
                confirmMessage="Thanks for subscribing!" 
              />
            </FormStyles>
          </StyledForm>
        </FormContainer>
      }</Sticky>
    </StickyContainer>
  );
}
  

export default BlogSidebar;

