import { queries } from "../../../../breakpoints"
import styled from 'styled-components';

export const FormContainer = styled.div`
    padding: 25px 15px;
    /* @media(${queries.max_break_lg}){
        display: none;
    } */
`;

export const StyledForm = styled.div`
    text-align:center;
    border: 1px solid var(--color-primary-dark)20;
    border-radius: 4px;
    display:flex;
    flex-flow:column;
    justify-content:center;
    align-items:center;
    padding: 24px 18px 18px;
    h5{
        font-weight: bold;
    }
    p{
        font-size: var(--fontSize-0);
    }
`; 

export const FormStyles = styled.div`
    width:100%;
    .mktoField{
        position:relative;
        border: 1px solid rgba(67,41,163,0.19);
        font-weight: var(--fontWeight-light);
        border-radius:4px;
        width:100%;
        padding: 10px;
        margin-bottom: 10px;
        height:45px;
        @media(${queries.min_break_md}){
            padding: 0 8px;
        }
    }
    &&& .mktoError {
        width: 100%;
        color: red;
        font-weight: var(--fontWeight-light);
        font-size: var(--fontSize-0);
        text-align:center;
    }
     &&& .mktoButton{
        width:100%;
        margin-top:10px;
    }
    // Validation styles
    .mktoInvalid{
        border-color:#C3363E;
    }
    .mktoErrorMsg{
        font-family: var(--fontFamily-body);
        color:#C3363E;
        font-size: 12px;
    }

    .mktoValid{
        border-color: #13AA37;
    }
`;

export const FormContainerSearch = styled.div`
    padding: 25px 15px;
    background: #FFF;
    border-radius: 4px;
    margin-bottom: 30px;
    border: 1px solid var(--color-primary-dark)20;
    @media(${queries.max_break_lg}){
        margin-top: 10px;
    }
    .formSearch {
        position: relative;
    }
    h5 {
        font-weight: 600;
    }
    input[type="text"] {
        position: relative;
        border: 1px solid rgba(67,41,163,0.19);
        font-weight: 300;
        border-radius: 4px;
        padding: 10px;
        height: 45px;
        width: 100%;
    }
    button {
        position: absolute;
        top: 2px;
        right: 5px;
        left: auto;
        bottom: 2px;
        background: #FFF;
        border: 0;
        padding: 10px;
        max-height: 41px;
        border-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;