import React from 'react';
import { FaSearch } from "react-icons/fa"

export const SearchBar = ({ searchQuery, setSearchQuery }) => {
    return (
        <form
            // action="/blog/"
            onSubmit={() => {
                window.location.replace(`/blog/?s=${searchQuery}`)
            }}
            method="get"
            autoComplete="off"
        >
            <div className='formSearch'>
                <input
                    value={searchQuery}
                    onInput={(e) => setSearchQuery(e.target.value)}
                    type="text"
                    id="header-search"
                    placeholder="Search for..."
                    name="s"
                />
                <button type="submit"><FaSearch size={18}/></button>
            </div>
        </form>
    )
};