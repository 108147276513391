import { BlogCardWrapper, Container, SeeMoreButton, StyledBlogDashboard } from './BlogDashboard.styles';
import React, { useState } from 'react';
import BlogCard from "../BlogCard"
import { FaArrowDown } from "react-icons/fa"
import PropTypes from 'prop-types';

const numberOfPostsToShow = 8;

const BlogDashboard = (props) => {
  const [ postsDisplayed, setPostsDisplayed ] = useState(numberOfPostsToShow);
  

  const seeMorePosts = () => {
    // Save the current amount of postsDisplayed to a const
    const currentAmount = postsDisplayed

    // Check if the current amount is less than 5 away from the max amount of posts
    if(currentAmount <= props.posts.length - 5){
      // Add 5 to the postsDisplayed
      setPostsDisplayed(currentAmount + 5)
    }else{
      // Set the postsDisplayed to the max
      setPostsDisplayed(props.posts.length)
    }
  }

  return(
    <Container>
      <StyledBlogDashboard>
        {props.posts && props.posts.slice(0, postsDisplayed).map((post, i) => {
          // If the index % 5 is 4 (meaning it's the 4th card) 
          // or the index % 5 is 0 (meaning it's the 5th card) 
          // then make it a big card
          let isBigCard = ((i + 1) % 5) === 4 || ((i + 1) % 5) === 0;

        return(
          // Add the isBigCard flag to make the card span 3 columns
          <BlogCardWrapper key={i} isBigCard={isBigCard}>
            <BlogCard 
              title={post.title} 
              gatsbyImageData={post?.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData}
              description={isBigCard ? post.excerpt : null}
              link={`/blog${post.uri}`}
              linkText="Learn more"
              tags={post.tags.nodes}
              />
          </BlogCardWrapper>
        )
      })}
    </StyledBlogDashboard>
    {props.posts.length >= numberOfPostsToShow && <SeeMoreButton 
      onClick={() => seeMorePosts()}
      // style={{ visibility: postsDisplayed == 0 ? 'visible': 'hidden'}}
      hidden={props.posts.length === postsDisplayed}
      >
      <p>See more</p> <FaArrowDown size={35}/> 
    </SeeMoreButton>
    }
    </Container>
  )
};

BlogDashboard.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    excerpt: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  })),
};

BlogDashboard.defaultProps = {
  // bla: 'test',
};

export default BlogDashboard;