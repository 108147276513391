import { GatsbyImage } from "gatsby-plugin-image";
import LinesEllipsis from 'react-lines-ellipsis'
import { queries } from '../../../../breakpoints';
import styled from 'styled-components';

export const ImagePlaceholder = styled.div`
    display: flex;
    width:100%;
    height:200px;
    border-radius: 4px 4px 0 0;
`;
export const StaticPlaceholder = styled.div`
    width:100%;
    height:200px;
    border-radius: 4px 4px 0 0;
    overflow:hidden;
`;
export const StyledGatsbyImage = styled(GatsbyImage)`
    border-radius: 4px 4px 0 0;
`;

export const StyledCardImage = styled.div`
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    flex:1;
    width:100%;
    border-radius: 4px 4px 0 0;
`;

export const Title = styled.h4`
    font-weight:bold;
    line-height: 30px;
`;

export const StyledContent = styled.div`
    flex:1;
    padding:15px 30px 25px;
    min-height:200px;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    
`;

export const Description = styled.span`
    max-height: 50px;
    overflow: hidden;
    margin-bottom:20px;
    color: black;
    @media(${queries.max_break_lg}){
        grid-column: span 3;
    }
`;

export const TruncatedDesc = styled(LinesEllipsis)`
    color: black;
    margin-bottom:20px;
    @media(${queries.max_break_lg}){
        display:none;
    }
`;

export const StyledBlogCard = styled.div`
    height:100%;
    display:flex;
    flex-flow:column;
    box-shadow: 0 2px 6px 0 rgba(207,198,198,0.5);
    border-radius:4px;
    transition: all .1s;
    &:hover{
        transform: scale(1.025);
        
    }
`;  

export const ContainerLink = styled.a`
    &:hover{
        text-decoration: none;
    }
`;