import { ContainerLink, ImagePlaceholder, StaticPlaceholder, StyledBlogCard, StyledContent, StyledGatsbyImage, Title, TruncatedDesc } from './BlogCard.styles';
import { stripHtmlTags, unescapeHTML } from "../../../../../helpers"

// import PropTypes from 'prop-types';
import Button from "../../../global/Button";
import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const BlogCard = (props) => {
  return(
    <ContainerLink href={props.link}>
      <StyledBlogCard>
          {props.gatsbyImageData && props.gatsbyImageData !== undefined ? 
            <ImagePlaceholder>
                <StyledGatsbyImage image={props.gatsbyImageData} alt=""/> 
            </ImagePlaceholder>
            :
            // If no Gatsby Image was supplied from the WP backend then display a static placeholder image
            <StaticPlaceholder>
              <StaticImage 
                src="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1613161021/HERMES/restaurant-assets/hero/placeholder_hero.png" 
                placeholder="blurred"
                layout="constrained"
                alt=""
                />
            </StaticPlaceholder>
          }
        <StyledContent>
          <StyledTopPart>
            <Title>
              {props.title}
            </Title>
            <StyledTagsContainer>
              {
                props.tags.map((tag, i) => {
                  return (
                    <StyledTag key={tag.id}>{tag.name}{`${i === props.tags.length -1? "" : ", "}`}</StyledTag>
                  )
                })
              }
            </StyledTagsContainer>
          </StyledTopPart>
          {props.description && <TruncatedDesc text={unescapeHTML(stripHtmlTags(props.description))} maxLine="2" ellipsis=" ..." trimRight basedOn="words"/>}
          {props.linkText && <Button type="tertiary">{props.linkText}</Button>}
        </StyledContent>
      </StyledBlogCard>
    </ContainerLink>
  )
};

// BlogCard.propTypes = {
//   // bla: PropTypes.string,
// };

// BlogCard.defaultProps = {
//   // bla: 'test',
// };

export default BlogCard;

const StyledTopPart = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const StyledTagsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StyledTag = styled.a`
  padding: 0px;
  margin: 5px 3px 5px 0px;
  border-radius: 20px;
  color: #ff8000;
  font-size: 12px;
  :hover {
    text-decoration: none;
    color: #ff8000;
  }
`;