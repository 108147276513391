import { CategorySelectorContainer, BlogHeading, MainContainer, CategorySelector } from './BlogHeader.styles';
import React from 'react';
import BlogCarousel from "../BlogCarousel";

const BlogCategorySelector = (props) => {

  const startFilter = (categoryName) => {
    if (categoryName === props.activeCategory) {
      props.filterByCategory()
    } else {
      props.filterByCategory(categoryName)
    }
  }

  return(
    <MainContainer>
      <BlogHeading>Your go-to source for helpful insights on corporate ordering and events</BlogHeading>
      {props.posts && props.posts.length > 0 && <BlogCarousel
        posts={props.posts}
      />}
      <CategorySelectorContainer>
        {props.categories && props.categories.map((category) => 
          <CategorySelector
            onClick={() => startFilter(category)}
            active={props.activeCategory === category}
          >{category}</CategorySelector>
        )}
      </CategorySelectorContainer>
    </MainContainer>
  )
};

export default BlogCategorySelector;
