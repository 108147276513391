import styled from 'styled-components';
import { queries } from '../../../../breakpoints';

export const CategorySelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px grey;
    justify-content: space-between;
    align-items: center;
    @media(${queries.max_break_lg}){
        flex-direction: column;
        flex-wrap: wrap;
    }
`;

export const BlogHeading = styled.h1`
    font-weight: bold;
    font-size: clamp(var(--fontSize-5), 3.5vw, 7rem);
    font-weight: bold;
    color: var(--color-secondary);
`;

export const MainContainer = styled.div`
    grid-area: header;
`;

export const CategorySelector = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    font-weight: bold;
    font-size: 20px;
    min-height: 100px;
    text-align: center;
    color: ${({ active }) => active ? "#000000" : "grey"};
    padding-bottom: ${({ active }) => active ? "0px" : "3px"};
    border-bottom: ${({active}) => active ? "solid 3px var(--color-primary);" : "none;"};
    @media(${queries.max_break_lg}){
        width: 100%;
        min-height: unset;
    }
`;