import styled from 'styled-components';
import { queries } from '../../../../breakpoints';

export const Container = styled.div`
    display: flex;
    flex-flow: column;
    grid-area: dashboard;
    @media(${queries.min_break_lg}){
        margin-right: 25px;
    }
`;

export const StyledBlogDashboard = styled.div`
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;
    /* margin: 25px 25px 0 0; */
    margin-top:25px;
`;


export const BlogCardWrapper = styled.div`
    grid-column: ${props => props.isBigCard ? "span 3" : "span 2"};
    @media(${queries.max_break_lg}){
        grid-column: span 3;
    }
    @media(${queries.max_break_md}){
        grid-column: span 6;
    }
`; 

export const SeeMoreButton = styled.button`
    margin-top: 50px;
    background-color: transparent;
    border: none;
    border-radius:5px;
    padding:20px 0%;
    transition: all .3s;
    p{
        font-family: var(--font-body);
        font-size: var(--fontSize-2);
        font-weight: bold;
        margin-bottom: 0;
    }
    svg{
        padding:5px;
        background-color: #C7B8FF30;
        border-radius: 50px;
        color:var(--color-primary);
    }
    &:hover{
        background-color:#e3e3e330;
    }
`;